<template>
  <div class="container-fluid">
    <NavMenu msg="This is a Nav" />
    <router-view></router-view>
    <!-- 页脚部分，显示域名备案号 -->
    <footer class="footer bg-light mt-5 py-3">
      <div class="container text-center">
        <p class="mb-0">
          <a href="https://beian.miit.gov.cn/">
            <strong>备案号：</strong>
            <span>{{ domainRecord }}</span>
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import NavMenu from './components/NavMenu.vue'
export default {
  name: 'App',
  components: {
    NavMenu
  },
  data() {
    return {
      domainRecord: '闽ICP备17018198号-1'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  text-align: center;
}
.footer a {
  text-decoration: none;
  color: #2c3e50;
}
</style>
