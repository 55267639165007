<template>
  <div class="container mt-5">
    <h2 class="mb-4">字符串长度计算器</h2>

    <!-- 输入字符串 -->
    <div class="form-group">
      <label for="stringInput">请输入字符串</label>
      <textarea
        rows="10"
        id="stringInput"
        class="form-control"
        v-model="inputString"
        placeholder="输入任意字符串"
      />
      <button class="btn btn-primary mt-2" @click="calculateLength">
        计算长度
      </button>
    </div>

    <!-- 显示计算结果 -->
    <div v-if="stringLength !== null" class="alert alert-info mt-3">
      字符串长度：{{ stringLength }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StringLengthCalculator',
  data() {
    return {
      inputString: '', // 用户输入的字符串
      stringLength: null // 计算得到的字符串长度
    }
  },
  methods: {
    calculateLength() {
      // 计算字符串的长度
      this.stringLength = this.inputString.length
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
</style>
