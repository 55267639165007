import { createRouter, createWebHistory } from 'vue-router'
import TimeStamp from '@/views/timestamp.vue'
import StringLengthCalculator from '@/views/StringLengthCalculator.vue'
import JsonFormatter from '@/views/JsonFormatter.vue'
import UrlEncoderDecoder from '@/views/UrlEncoderDecoder.vue'

// import Index from '../views/Index.vue'

// 路由配置
const routes = [
  {
    path: '/TimeStamp',
    name: 'TimeStamp',
    component: TimeStamp
  },
  {
    path: '/TimeStamp',
    name: 'TimeStamp',
    component: TimeStamp
  },
  {
    path: '/JsonFormatter',
    name: 'JsonFormatter',
    component: JsonFormatter
  },
  {
    path: '/StringLengthCalculator',
    name: 'StringLengthCalculator',
    component: StringLengthCalculator
  },
  {
    path: '/UrlEncoderDecoder',
    name: 'UrlEncoderDecoder',
    component: UrlEncoderDecoder
  }
]

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
