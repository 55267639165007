<template>
  <div class="container mt-5">
    <h2 class="mb-4">JSON 格式化、压缩、转义和去除转义工具</h2>

    <!-- 输入 JSON 字符串 -->
    <div class="form-group">
      <label for="jsonInput">请输入 JSON 字符串</label>
      <textarea
        id="jsonInput"
        class="form-control"
        v-model="jsonInput"
        rows="6"
        placeholder="输入有效的 JSON 字符串"
      ></textarea>
    </div>

    <!-- 操作按钮 -->
    <div class="mb-3 mt-3">
      <button class="btn btn-primary" @click="formatJson">格式化 JSON</button>
      <button class="btn btn-secondary ml-3" @click="compressJson">
        压缩 JSON
      </button>
      <button class="btn btn-warning ml-3" @click="escapeJson">
        转义 JSON
      </button>
      <button class="btn btn-danger ml-3" @click="removeEscape">
        去除转义
      </button>
    </div>

    <!-- 显示处理结果 -->
    <div v-if="processedJson" class="alert alert-info mt-3">
      <strong>处理结果：</strong>
      <pre>{{ processedJson }}</pre>
    </div>

    <!-- 错误提示 -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      <strong>错误：</strong> {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonFormatter',
  data() {
    return {
      jsonInput: '', // 用户输入的 JSON 字符串
      processedJson: '', // 处理后的 JSON 字符串
      errorMessage: '' // 错误信息
    }
  },
  methods: {
    // 格式化 JSON
    formatJson() {
      this.clearError()
      try {
        const parsed = JSON.parse(this.jsonInput) // 解析 JSON 字符串
        this.processedJson = JSON.stringify(parsed, null, 2) // 格式化 JSON
      } catch (error) {
        this.handleError('无效的 JSON 字符串。请检查语法。')
      }
    },

    // 压缩 JSON
    compressJson() {
      this.clearError()
      try {
        const parsed = JSON.parse(this.jsonInput) // 解析 JSON 字符串
        this.processedJson = JSON.stringify(parsed) // 压缩 JSON（去除所有空格和缩进）
      } catch (error) {
        this.handleError('无效的 JSON 字符串。请检查语法。')
      }
    },

    // 转义 JSON
    escapeJson() {
      this.clearError()
      try {
        // 转义 JSON 字符串中的特殊字符
        this.processedJson = JSON.stringify(this.jsonInput) // 使用 stringify 自动转义
      } catch (error) {
        this.handleError('无效的 JSON 字符串。请检查语法。')
      }
    },

    // 去除转义字符
    removeEscape() {
      this.clearError()
      try {
        // 去除转义字符
        const unescaped = JSON.parse(this.jsonInput) // 解析 JSON 字符串
        this.processedJson = JSON.stringify(unescaped) // 将其重新转为 JSON 字符串
      } catch (error) {
        this.handleError('无效的 JSON 字符串。请检查语法。')
      }
    },

    // 清空错误信息
    clearError() {
      this.errorMessage = ''
      this.processedJson = '' // 清空处理结果
    },

    // 处理错误信息
    handleError(message) {
      this.processedJson = ''
      this.errorMessage = message
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
button {
  margin-right: 15px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
