<template>
  <div class="container mt-5">
    <h2 class="mb-4">URL 加密解密工具</h2>

    <!-- 输入 URL -->
    <div class="form-group">
      <label for="urlInput">请输入 URL</label>
      <input
        type="text"
        id="urlInput"
        class="form-control"
        v-model="urlInput"
        placeholder="输入需要加密或解密的 URL"
      />
    </div>

    <!-- 操作按钮 -->
    <div class="mb-3 mt-3">
      <button class="btn btn-primary" @click="encodeUrl">加密 URL</button>
      <button class="btn btn-secondary" @click="decodeUrl">解密 URL</button>
    </div>

    <!-- 显示结果 -->
    <div v-if="processedUrl" class="alert alert-info mt-3">
      <strong>处理结果：</strong>
      <pre>{{ processedUrl }}</pre>
    </div>

    <!-- 错误提示 -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      <strong>错误：</strong> {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UrlEncoderDecoder',
  data() {
    return {
      urlInput: '', // 用户输入的 URL 字符串
      processedUrl: '', // 处理后的 URL 字符串
      errorMessage: '' // 错误信息
    }
  },
  methods: {
    // 加密 URL
    encodeUrl() {
      this.clearError()
      try {
        // 使用 encodeURIComponent 对 URL 进行编码
        this.processedUrl = encodeURIComponent(this.urlInput)
      } catch (error) {
        this.handleError('无效的 URL 字符串。请检查输入。')
      }
    },

    // 解密 URL
    decodeUrl() {
      this.clearError()
      try {
        // 使用 decodeURIComponent 对 URL 进行解码
        this.processedUrl = decodeURIComponent(this.urlInput)
      } catch (error) {
        this.handleError('无效的 URL 字符串。请检查输入。')
      }
    },

    // 清空错误信息
    clearError() {
      this.errorMessage = ''
      this.processedUrl = '' // 清空处理结果
    },

    // 处理错误信息
    handleError(message) {
      this.processedUrl = ''
      this.errorMessage = message
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
button {
  margin-right: 15px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
